<template>
    <a-modal
        :visible="visible"
        title="Unggah Master Target Salesman"
        @cancel="handleModalCancel"
        width="70%"
        :destroy-on-close="true">

        <template #footer>
            <AButton
                @click="handleModalCancel">Batal</AButton>
            <AButton
                key="submit"
                type="primary"
                :loading="state.loading"
                @click="handleSubmitOk">Submit</AButton>
        </template>

        <AForm
            ref="formRef"
            class="myform"
            :model="state.form"
            :rules="state.rules"
            @finish="btnUploadFile()"
            :wrapper-col="{ span: 14 }"
            :scroll-to-first-error="true">

            <!-- file -->
            <a-row class="form-row">
                <a-col :sm="24">
                    <a-form-item
                        label="Unggah Dokumen"
                        label-align="left"
                        :label-col="{ sm: { span: 4 } }"
                        :wrapper-col="{ sm: { span: 24 - 4 } }"
                        has-feedback>
                        <a-input
                            type="file"
                            required
                            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                            @change="onFileChange"
                        ></a-input>
                    </a-form-item>
                </a-col>
            </a-row>

            <!-- bulan -->
            <a-row class="form-row">
                <a-col :sm="24">
                    <a-form-item
                        label="Bulan"
                        label-align="left"
                        :label-col="{ sm: { span: 4 } }"
                        :wrapper-col="{ sm: { span: 24 - 4 } }"
                        required
                        name="month"
                        has-feedback>
                            <AMonthPicker
                                v-model:value="state.form.month"
                                format="MMMM YYYY"
                                :disabled-date="disabledDate"
                                style="width:300px"
                                placeholder="Pilih Bulan"/>
                    </a-form-item>
                </a-col>
            </a-row>

            <!-- pilih user TSO -->
            <ARow
                v-if="hasRoles([ROLE_ADMIN_BK])"
                class="form-row">
                <ACol :xl="12" :md="24">
                    <AFormItem
                        label="Pilih User TSO"
                        label-align="left"
                        :label-col="{ md: { span: 8 } }"
                        :wrapper-col="{ md: { span: 24 - 8 } }"
                        required
                        name="useram_id"
                        has-feedback>
                        <FilterUsers
                            v-model:value="state.form.useram_id"
                            :status_mapping="1"
                            :disabled="!state.form.month"
                            :mode="null"
                            style="width:100%;"
                            placeholder="Pilih User TSO"/>
                    </AFormItem>
                </ACol>
            </ARow>

            <!-- button -->
            <a-row class="form-row mb-4">
                <ACol :sm="4"/>
                <a-col :sm="20">
                    <ASpace>
                        <a-button
                            type="primary"
                            html-type="submit"
                            :loading="state.form.loading">Upload</a-button>

                        <a-tooltip>
                            <template
                                v-if="!state.form.month || !state.form.useram_id"
                                #title>Pilih bulan {{ hasRoles([ROLE_ADMIN_BK]) ? 'dan user TSO' : '' }}sebelum download template.</template>
                            <AButton
                                @click="btnDownloadTemplate()"
                                :disabled="!state.form.month || !state.form.useram_id"
                                :loading="state.loadingTemplate">Unduh Template</AButton>
                        </a-tooltip>
                    </ASpace>
                </a-col>
            </a-row>
        </AForm>

        <AAlert
            v-if="errorMessage"
            type="error"
            :message="errorMessage"
            banner
            closable
            @close="errorMessage = null"/>

        <br>

        <MdTable
            :columns="state.columns"
            :data-source="state.data"
            row-key="salesman_id"
            :row-selection="rowSelection"
            size="small"
            :loading="state.loading">
            <template #tags="{ record }">
                <a-tag :color="record.errors.length == 0 ? 'green' : 'volcano'">
                <template #icon>
                    <template v-if="record.errors.length == 0"> <CheckCircleOutlined /> Ya </template>
                    <template v-else>
                        <APopover trigger="click">
                            <template #content>
                                <AList size="small" :data-source="record.errors">
                                    <template #renderItem="{ item }">
                                        <AListItem>{{ item.message }}</AListItem>
                                    </template>
                                </AList>
                            </template>
                            <CloseCircleOutlined /> Tidak
                        </APopover>
                    </template>
                </template>
                </a-tag>
            </template>
        </MdTable>

         <AModal
            v-model:visible="state.result.isShow"
            title="Result"
            @cancel="handleModalCancel"
            @ok="handleModalCancel">
            <template #footer>
                <AButton key="back" type="primary" @click="handleShowCancel">OK</AButton>
            </template>
            <AAlert type="success" :message="`Sukses: ${state.result.valid}`"></AAlert>
            <AAlert type="error">
                <template #message>
                    {{ `Gagal: ${state.result.failed}` }}
                    <a-tree :tree-data="state.result.data" default-expand-all/>
                </template>
            </AAlert>
        </AModal>

    </a-modal>
</template>
<script>
import {
    defineComponent,
    onMounted,
    reactive,
    ref,
    computed,
} from 'vue'
import apiClient from '@/services/axios'
import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons-vue'
import { Modal, message } from 'ant-design-vue'
import moment from 'moment'
import FilterUsers from '@/components/filter/FilterUsers'
import localStorage from 'store'
import {
    ROLE_ADMIN_BK,
    ROLE_TSO,
    hasRoles,
} from '@/helpers'

export default defineComponent({
    components: {
        CheckCircleOutlined,
        CloseCircleOutlined,
        FilterUsers,
    },
    props: {
        visible: [Boolean],
    },
    emits: ['btnUploadFileConversionPoint', 'update:visible'],
    setup(props, { emit }) {
        const errorMessage = ref()
        const user = computed(() => localStorage.get('profile'))

        const formRef = ref();

        const state = reactive({
            columns: [
                {
                    title: 'Valid?',
                    dataIndex: 'valid',
                    slots: {
                        customRender: 'tags',
                    },
                },
                {
                    title: 'ID Salesman',
                    dataIndex: 'salesman_id',
                },
                {
                    title: 'Nama Salesman',
                    dataIndex: 'salesman_name',
                },
                {
                    title: 'Kode Distributor',
                    dataIndex: 'vendor_id',
                },
                {
                    title: 'Distributor',
                    dataIndex: 'vendor_name',
                },
                {
                    title: 'Target',
                    children: [
                        {
                            title: 'Hari Kerja',
                            dataIndex: 'target_hari_kerja',
                            align: 'center',
                        },
                        {
                            title: 'Visit',
                            dataIndex: 'target_visit',
                            align: 'center',
                        },
                        {
                            title: 'Coverage',
                            dataIndex: 'target_coverage',
                            align: 'center',
                        },
                        {
                            title: 'Customer Active',
                            dataIndex: 'target_customer_active',
                            align: 'center',
                        },
                        {
                            title: 'NOO',
                            dataIndex: 'target_new_outlet',
                            align: 'center',
                        },
                        {
                            title: 'Volume',
                            dataIndex: 'target_volume',
                            align: 'center',
                        },
                    ],
                },
            ],
            rules: {
                month: [
                    {
                        required: true,
                        message: "Bulan tidak boleh kosong!",
                    },
                ],
                useram_id: [
                    {
                        required: true,
                        message: "User TSO tidak boleh kosong!",
                    },
                ],
            },
            url_template: '/api/atsalesman/target/unduh-template',
            data: [],
            loading: false,
            loadingTemplate: false,
            meta: {
                per_page: 10,
                page: 1,
                total: 0,
            },
            selectedRowKeys: [],
            selectedRows: [],
            result: {
                isShow: false,
                data: [],
                valid: 0,
                failed: 0,
            },
            form: {
                loading: false,
                files: null,
                file: null,
                month: null,
                useram_id: null,
            },
        })

        const handleModalCancel = () => {
            emit('update:visible', false)
        }

        const disabledDate = current => {
            // TODO: sementara waktu untuk testing
            // if (current.isSame(new Date(), 'month')) {
            //     return moment().startOf('month').add(10, 'days').isBefore(current)
            // }

            return current && current < moment().startOf('month')
        }

        const onFileChange = (e) => {
            var files = e.target.files || e.dataTransfer.files
            if (!files.length) return
            state.form.file = files[0]
        }

        const rowSelection = computed(() => {
            return {
                selectedRowKeys: state.selectedRowKeys,
                onChange: (selectedRowKeys, selectedRows) => {
                    state.selectedRowKeys = selectedRowKeys
                    state.selectedRows = selectedRows
                },
                getCheckboxProps: record => ({
                    disabled: record.errors.length > 0,
                }),
            }
        })

        const btnUploadFile = async () => {
            // validation form
            await formRef.value.validate()
                .catch(() => { });

            state.form.loading = true
            const form_data = new FormData()

            form_data.append('file', state.form.file)
            form_data.append('month', moment(state.form.month).format('YYYY-MM'))

            apiClient.post(`/api/atsalesman/target/upload`, form_data)
                .then(({ data }) => {
                    state.data = data
                })
                .catch(async error => {
                    errorMessage.value = null

                    if (error.response && error.response.status !== 500) {
                        const { status, statusText } = error.response
                        const message = JSON.parse(await error.response.data.text()).message
                        errorMessage.value = `Kode error ${status}, ${statusText} : ${message}`
                    }

                    if (error.response && error.response.status === 500) {
                        const { status, statusText } = error.response
                        const message = error.response.data.message
                        errorMessage.value = `Kode error ${status}, ${statusText} : ${message}`
                    }

                    message.error('Gagal mengungah!')
                })
                .finally(() => {
                    state.form.loading = false
                })
        }

        const handleSubmitOk = async () => {
            state.result.data = []
            errorMessage.value = null
            state.loading = true

            let payloads = []
            state.selectedRows.forEach(item => {
                payloads.push({
                    ...item,
                    valid: item.errors.length == 0,
                })
            })

            apiClient
                .post('/api/atsalesman/target/upload', { simpan: payloads })
                .then(({ data }) => {
                    if (Array.isArray(data) && data.length === 0) {
                        message.warning('Tidak ada data!, pastikan sudah memilih datanya')
                        return
                    }

                    state.result.isShow = true

                    data.forEach((item, idx) => {
                        if (item.success) {
                            state.result.valid++
                        } else {
                            state.result.failed++
                            const branch = {}
                            state.result.data.push(branch)
                            branch.title = 'Baris ' + (idx + 1)
                            branch.key = idx
                            branch.children = []
                            if (item.model.errors) {
                                item.model.errors.forEach((v, i) => {
                                    branch.children.push({
                                        title: `Kolom ${v.field} : ${v.message}`,
                                        key: `${idx}-${i}`,
                                    })
                                })
                            }
                        }
                    })
                    
                    emit('success', data)
                })
                .catch(async error => {
                    errorMessage.value = null
                    if (error.response) {
                        const { status, statusText } = error.response
                        const message = error.response.data.message
                        errorMessage.value = `Kode error ${status}, ${statusText} : ${message}`
                    }
                })
                .finally(() => {
                    state.loading = false
                    state.selectedRowKeys = []
                    state.selectedRows = []
                })
        }

        const btnDownloadTemplate = async () => {
            state.loadingTemplate = true

            apiClient
                .post(state.url_template, null, {
                    params: {
                        ...state.form,
                        month: moment(state.form.month).format('YYYY-MM'),
                        _export: 'xls',
                    },
                    responseType: 'blob',
                    headers: {},
                })
                .then(response => {
                    if (response === undefined) {
                        errorMessage.value = `Kode error 500, No response from server`
                        return
                    }
                    const url = URL.createObjectURL(
                        new Blob([response.data], {
                            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                        }),
                    )
                    const link = document.createElement('a')
                    link.href = url
                    link.setAttribute('download', `Master-Data-Target_${moment().format('DDMMYY_HHmm')}.xlsx`)
                    document.body.appendChild(link)
                    link.click()
                })
                .catch(async error => {
                    errorMessage.value = null
                    if (error.response) {
                        const { status, statusText } = error.response
                        const message = JSON.parse(await error.response.data.text()).message
                        errorMessage.value = `Kode error ${status}, ${statusText} : ${message}`
                    }
                })
                .finally(() => {
                    state.loadingTemplate = false
                })
        }

        const handleShowCancel = () => {
            state.result.isShow = false
            handleModalCancel()
        }

        // handle vue
        onMounted(() => {
            if (hasRoles([ROLE_TSO])) {
                state.form.useram_id = user.value.id
            }
        })

        return {
            errorMessage,
            state,
            formRef,
            btnUploadFile,
            handleSubmitOk,
            handleModalCancel,
            onFileChange,
            rowSelection,
            handleShowCancel,
            disabledDate,
            btnDownloadTemplate,
            // only variable role
            hasRoles,
            ROLE_ADMIN_BK,
        }
    },
})
</script>

<style lang="scss" scoped>
@import '@/css/form.scss';
</style>
